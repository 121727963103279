import { Container, Row, Col } from "react-bootstrap";
import educationImg from "../assets/img/12.jpg";

export const Education = () => {
  return (
    <section className="education" id="education">
      <Container>
        <Row>
          <Col>
            <h2>
              {`Education`}
              <br />{" "}
            </h2>
            <h3>
              Shanghai International Studies University
              <br />
              <p>Journalism Bilingual Communication - Bachelor</p>
            </h3>
            <h4>
              Newcastle University,UK
              <br />
              <p>Media and Public Relations - Master</p>
            </h4>
          </Col>
        </Row>
      </Container>
      <img src={educationImg} alt="education img" />
    </section>
  );
};
